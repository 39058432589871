import { useCallback, useEffect } from "react";

import { useQuery } from "@tanstack/react-query";

const eventTarget = new EventTarget();

type UseStorageOptions<T> = {
  defaultValue?: T;
};

function useStorage<T>(
  storage: Storage,
  key: string,
  options: UseStorageOptions<T>,
): [T, (value: T) => void] {
  const queryFn = () => {
    const value = storage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return options.defaultValue ?? null;
  };
  const { data, refetch } = useQuery({
    queryKey: ["storage", key, options.defaultValue],
    queryFn,
    placeholderData: options.defaultValue,
    initialData: queryFn,
  });

  useEffect(() => {
    const listener = (event: Event) => {
      if (!(event instanceof StorageEvent)) {
        return;
      }
      if (event.key === key) {
        refetch();
      }
    };
    window.addEventListener("storage", listener);
    eventTarget.addEventListener("storage", listener);
    return () => {
      window.removeEventListener("storage", listener);
      eventTarget.removeEventListener("storage", listener);
    };
  }, [key, refetch]);

  const setValue = useCallback(
    (value: T) => {
      storage.setItem(key, JSON.stringify(value));
      eventTarget.dispatchEvent(new StorageEvent("storage", { key }));
    },
    [key, storage],
  );

  return [data, setValue];
}

export function useLocalStorage<T>(
  key: string,
  options: UseStorageOptions<T> = {},
): [T, (value: T) => void] {
  return useStorage(localStorage, key, options);
}

export function useSessionStorage<T>(
  key: string,
  options: UseStorageOptions<T> = {},
): [T, (value: T) => void] {
  return useStorage(sessionStorage, key, options);
}
