import { createContext, useContext, useMemo, type JSX } from "react";

import { Trans } from "@lingui/macro";
import { MSAxiosInstance } from "@mobsuccess-devops/rdc-react-client";
import { Loader } from "@mobsuccess-devops/react-ui/_PandaArk";
import { useQuery } from "@tanstack/react-query";

import type { AxiosInstances } from "../../features/axios-instances";
import { useAuth } from "../../public/auth";

const AxiosContext = createContext<AxiosContextType | null>(null);

MSAxiosInstance.defaults.baseURL =
  import.meta.env.VITE_DATA_CONTEXT_SERVICE_RDC;

export function useAxiosContext(): AxiosContextType {
  const context = useContext(AxiosContext);
  if (!context) {
    throw new Error("useAxiosContext must be used within an AxiosProvider");
  }
  return context;
}

type AxiosContextType = {
  orchestrator: AxiosInstances;
};

type AxiosContextProviderProps = {
  orchestrator: AxiosInstances;
  children: JSX.Element | null;
};

export function AxiosContextProvider({
  children,
  orchestrator,
}: AxiosContextProviderProps): JSX.Element | null {
  const { bearer } = useAuth();

  const value = useMemo<AxiosContextType>(
    () => ({ orchestrator }),
    [orchestrator],
  );

  const { isLoading } = useQuery({
    queryKey: ["load.url.overrides", bearer],
    queryFn: orchestrator.loadUrlOverrides(bearer),
  });

  if (isLoading) {
    return (
      <Loader.Block>
        <Loader.Ellipsis />
        <Loader.Description>
          <Trans>#AxiosContextProvider.setting-up-environnement</Trans>
        </Loader.Description>
      </Loader.Block>
    );
  }

  return (
    <AxiosContext.Provider value={value}>{children}</AxiosContext.Provider>
  );
}
