import { useEffect, useMemo, useState, type RefObject } from "react";

type ReturnValues = {
  itemsStyles: Array<Record<string, string>>;
  someChildrenAreHidden: boolean;
  restNumber: number;
};

export function useOverflowTags(
  tagsInputRef: RefObject<HTMLDivElement | null>,
  tagsValue: Array<unknown>,
): ReturnValues {
  const [update, setUpdate] = useState(0);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setUpdate((prev) => prev + 1);
    });
    if (tagsInputRef.current) {
      resizeObserver.observe(tagsInputRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [tagsInputRef]);

  return useMemo(() => {
    const threshold = 200;
    const inputWidth = tagsInputRef.current?.clientWidth ?? 0;
    const previewItems = tagsInputRef.current?.querySelectorAll<HTMLDivElement>(
      "div[data-scope='tags-input'][data-part='item']",
    );
    if (!previewItems) {
      return {
        itemsStyles: [],
        someChildrenAreHidden: false,
        restNumber: 0,
      };
    }
    let lastVisibleChildIndex = Infinity;
    let someChildrenAreHidden = false;
    const itemsStyles = Array.from(previewItems).map((el, index) => {
      const bound = el.getBoundingClientRect();
      const rightEdge = bound.right;
      const overflowing = rightEdge > inputWidth - threshold;
      if (overflowing) {
        lastVisibleChildIndex = Math.min(lastVisibleChildIndex, index);
      }
      const display = index <= lastVisibleChildIndex ? "block" : "none";
      someChildrenAreHidden =
        someChildrenAreHidden || index > lastVisibleChildIndex;
      return {
        "--display": display,
      };
    });
    return {
      itemsStyles,
      someChildrenAreHidden,
      restNumber: tagsValue.length - (lastVisibleChildIndex + 1),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- need update to recompute on resize
  }, [tagsInputRef, tagsValue, update]);
}
