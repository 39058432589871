import { useCallback, useMemo, type JSX } from "react";

import type { I18n, Messages } from "@lingui/core";
import { t } from "@lingui/macro";
import { I18nProvider } from "@lingui/react";
import { useToasters } from "@mobsuccess-devops/react-ui/_PandaArk";

import {
  LanguageContext,
  changeLocale,
  APP_LOCALES,
  type LanguageContextType,
} from "./i18n";
import languagesMap from "./languages-map";
import { ReactUILocales } from "./react-ui";

type LocalizedProps = {
  i18n: I18n;
  locales: Map<string, Messages>;
  children: React.ReactNode;
};

// TODO: update the rule for react-shared
// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
export function Localized({
  locales,
  children,
  i18n,
}: LocalizedProps): JSX.Element {
  const { toast } = useToasters();
  const handleChangeLanguage = useCallback<
    LanguageContextType["onLanguageChange"]
  >(
    async (newLanguage) => {
      try {
        await changeLocale(locales, newLanguage);
      } catch {
        toast.error({
          title: t`#src.public.Localized.errorTitle`,
          description: t`#src.public.Localized.errorDescription`,
        });
      }
    },
    [locales, toast],
  );

  const value = useMemo<LanguageContextType>(() => {
    return {
      language: i18n.locale,
      languagesMap,
      availableLanguages: APP_LOCALES,
      onLanguageChange: handleChangeLanguage,
    };
  }, [handleChangeLanguage, i18n.locale]);

  return (
    <LanguageContext.Provider value={value}>
      <I18nProvider i18n={i18n}>
        <ReactUILocales>{children}</ReactUILocales>
      </I18nProvider>
    </LanguageContext.Provider>
  );
}
