/* eslint-disable @mobsuccess-devops/mobsuccess/react */
import { type JSX, useRef } from "react";

import { Combobox, TagsInput, useCombobox, useTagsInput } from "@ark-ui/react";
import { IconArrowDouble, IconClose } from "@mobsuccess-devops/icons";
import { cx } from "@mobsuccess-devops/styled-system/css";

import { inputStyles } from "../../../features/shared-styles/input";
import { textFieldDecorationStyles } from "../../../features/shared-styles/text-fields";
import { composition } from "../../../features/utils/composition";
import { Typo } from "../../Typo";
import { Button } from "../Button/Button";
import { Input } from "../Input/Input";

import {
  SelectIcon,
  SelectTrigger,
  SelectAddonTrigger,
  defaultFilter,
} from "./Decorators";
import type { SelectItem, BaseSelectProps } from "./Select";
import { SelectItemsDropdown } from "./SelectItemsDropdown";
import { styles } from "./styles";
import { useOverflowTags } from "./tags";

type SelectMultipleProps<T extends SelectItem> = Omit<
  BaseSelectProps<T>,
  "multiple"
>;

export function SelectMultiple<T extends SelectItem>({
  items,
  collection,
  isSearchable = true,
  width,
  filterFunction = defaultFilter,
  children,
  size,
  ...props
}: SelectMultipleProps<T>): JSX.Element {
  const decorators = composition.split(children, {
    icon: composition.single(SelectIcon),
    trigger: composition.single([SelectTrigger, SelectAddonTrigger]),
  });

  const classes = styles({
    addon: decorators.trigger && decorators.trigger.type === SelectAddonTrigger,
    size,
  });

  const inputClasses = inputStyles({
    size,
  });
  const decoration = textFieldDecorationStyles();
  const tagsInputRef = useRef<HTMLDivElement | null>(null);

  const combobox = useCombobox<T>({
    collection,
    multiple: true,
    closeOnSelect: false,
    ...props,
  });

  const tagsInput = useTagsInput({
    value: combobox.value,
    readOnly: true,
    onValueChange: ({ value }) => combobox.setValue(value),
  });

  const { itemsStyles, someChildrenAreHidden, restNumber } = useOverflowTags(
    tagsInputRef,
    tagsInput.value,
  );

  return (
    <Combobox.RootProvider
      value={combobox}
      className={cx(classes.root, props.className)}
    >
      {decorators.trigger ? (
        decorators.trigger
      ) : (
        <Combobox.Control
          // @ts-expect-error -- button onclick applied to div
          onClick={
            combobox.open ? undefined : combobox.getTriggerProps().onClick
          }
        >
          <TagsInput.RootProvider value={tagsInput}>
            <TagsInput.Control
              className={cx(inputClasses.root, decoration, "group")}
              ref={tagsInputRef}
            >
              {decorators.icon && (
                <Input.Prefix>{decorators.icon}</Input.Prefix>
              )}
              {combobox.selectedItems.map((item, index) => (
                <TagsInput.Item
                  index={index}
                  value={item.value}
                  key={index + "//" + item.value}
                  className={classes.tag}
                  style={itemsStyles?.[index]}
                >
                  <TagsInput.ItemPreview className={classes.tagPreview}>
                    <TagsInput.ItemText asChild>
                      <Typo.Body color="primary">{item.label}</Typo.Body>
                    </TagsInput.ItemText>
                    <TagsInput.ItemDeleteTrigger
                      value={item.value}
                      onClick={(event) => {
                        event.stopPropagation();
                        combobox.setValue(
                          combobox.value.filter((i) => i !== item.value),
                        );
                      }}
                      asChild
                    >
                      <Button
                        variant="transparent"
                        size="xs"
                        data-tracking-id="AN47RxGaDz"
                      >
                        <IconClose variant="regular" size="xs" />
                      </Button>
                    </TagsInput.ItemDeleteTrigger>
                  </TagsInput.ItemPreview>
                </TagsInput.Item>
              ))}
              {someChildrenAreHidden && (
                <div className={classes.tag}>
                  <div className={classes.tagPreview}>
                    <Typo.Body color="primary">+{restNumber}</Typo.Body>
                  </div>
                </div>
              )}
              <Combobox.Input
                readOnly={!isSearchable}
                placeholder={combobox.value.length ? "" : props.placeholder}
                className={inputClasses.input}
              />
              <Input.Suffix>
                <IconArrowDouble size="8px" color="interface" />
              </Input.Suffix>
              <TagsInput.HiddenInput />
            </TagsInput.Control>
          </TagsInput.RootProvider>
        </Combobox.Control>
      )}
      <SelectItemsDropdown
        items={items}
        width={width}
        isSearchable={isSearchable}
        filterFunction={filterFunction}
        multiple
      />
    </Combobox.RootProvider>
  );
}
