import { captureException } from "@sentry/react";

class SentryXHRError extends Error {
  constructor({
    status,
    responseURL,
  }: {
    status: number;
    responseURL: string;
  }) {
    super(
      `Received HTTP code ${status} when fetching XHR from ${
        new URL(responseURL).hostname
      }`,
    );
    this.name = "SentryXHRError";
  }
}
export function initXMLHttpRequest(): void {
  window.XMLHttpRequest = new Proxy(window.XMLHttpRequest, {
    construct(target) {
      const xhr = new target();
      xhr.addEventListener("load", () => {
        const { status, responseURL } = xhr;

        if (status >= 400) {
          // log error to Sentry
          captureException(new SentryXHRError({ status, responseURL }), {
            extra: { ...xhr },
            tags: {
              interceptor: "XHRInterceptor",
              httpStatusCode: status,
            },
          });
        }
      });
      return xhr;
    },
  });
}
