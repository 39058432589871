import { useCallback, type JSX } from "react";

import { Trans } from "@lingui/macro";
import { DarkButton } from "@mobsuccess-devops/react-ui/Button";
import { Container } from "@mobsuccess-devops/react-ui/Container";
import { Typography } from "@mobsuccess-devops/react-ui/Typography";

import styled, { useTheme, css } from "styled-components";

import { useAuth } from "../../../public/auth/auth";

const quickActionCss = css`
  text-decoration: underline;
  cursor: pointer;
`;

const ButtonUI = styled(DarkButton)`
  width: 100%;
  justify-content: center;
`;

type EmailSentProps = {
  email: string;
  onRetry: () => void;
  onBackToLogin: () => void;
};

const separator = "/";

function EmailSent({
  email: userEmail,
  onRetry,
  onBackToLogin,
}: EmailSentProps): JSX.Element {
  const { forgotPassword } = useAuth();
  const { palette } = useTheme();
  const handleSend = useCallback(() => {
    forgotPassword({
      email: userEmail,
    });
  }, [userEmail, forgotPassword]);

  const email = (
    <Typography
      as="span"
      variant="md-bold"
      color={palette.primary.lighter[100]}
    >
      {userEmail}
    </Typography>
  );

  return (
    <Container
      flexDirection="column"
      width="100%"
      gap="24px"
      alignItems="center"
    >
      <Typography
        as="span"
        variant="md-light"
        color={palette.interface.lighter[100]}
      >
        <Trans>#Auth.EmailSent.email-sent.{email}</Trans>
      </Typography>
      <ButtonUI
        size="lg"
        formNoValidate
        form="auth-form"
        color="primary"
        onClick={onBackToLogin}
      >
        <Trans>#Auth.EmailSent.back</Trans>
      </ButtonUI>
      <Container gap="4px" justifyContent="center">
        <Typography
          as="a"
          variant="md-light"
          color={palette.primary.base}
          styled={quickActionCss}
          onClick={onRetry}
        >
          <Trans>#Auth.EmailSent.wrong-email</Trans>
        </Typography>
        <Typography
          as="span"
          variant="md-light"
          color={palette.primary.lighter[100]}
        >
          {separator}
        </Typography>
        <Typography
          as="a"
          variant="md-light"
          styled={quickActionCss}
          onClick={handleSend}
          color={palette.primary.base}
        >
          <Trans>#Auth.EmailSent.resend</Trans>
        </Typography>
      </Container>
    </Container>
  );
}

export { EmailSent };
