import type { JSX } from "react";

import {
  cva,
  cx,
  type RecipeVariantProps,
} from "@mobsuccess-devops/styled-system/css";
import { hstack } from "@mobsuccess-devops/styled-system/patterns";

import { Dot, type styles as dotStyles } from "./Dot";

type EllipsisProps = RecipeVariantProps<typeof styles> &
  RecipeVariantProps<typeof dotStyles> & { className?: string } & object;

export function Ellipsis({
  size,
  color,
  className,
}: EllipsisProps): JSX.Element {
  const classes = styles({ size });
  return (
    <div className={cx(classes, className)}>
      <Dot size={size} color={color} />
      <Dot size={size} color={color} delay={1} />
      <Dot size={size} color={color} delay={2} />
    </div>
  );
}

export const styles = cva({
  base: hstack.raw(),
  variants: {
    size: {
      sm: {
        gap: "2px",
      },
      md: {
        gap: "4px",
      },
      lg: {
        gap: "6px",
      },
    },

    shown: {
      false: {
        display: "none",
      },
    },
  },
  defaultVariants: {
    size: "md",
    shown: true,
  },
});
