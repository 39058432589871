import type { JSX } from "react";

import {
  sva,
  type RecipeVariantProps,
} from "@mobsuccess-devops/styled-system/css";
import { circle } from "@mobsuccess-devops/styled-system/patterns";

type DotProps = NonNullable<RecipeVariantProps<typeof styles>>;

export function Dot({ delay, size, color }: DotProps): JSX.Element {
  const classes = styles({ delay, size, color });
  return (
    <div className={classes.root}>
      <div className={classes.shadow} />
      <div className={classes.ball} />
    </div>
  );
}

export const styles = sva({
  slots: ["root", "balls", "ball", "shadows", "shadow"],
  base: {
    root: {
      position: "relative",
      width: "fit-content",
      center: true,
    },
    shadows: {
      display: "flex",
      position: "absolute",
      bottom: "0px",
    },
    ball: {
      ...circle.raw(),
      backgroundColor: "primary",
      animation: "bounce-inline 0.6s ease-in infinite alternate",
      animationDelay: "calc(var(--delay, 0) * 0.2s)",
    },
    shadow: {
      position: "absolute",
      bottom: "0px",
      borderRadius: "50%",
      backgroundColor: "rgba(79,91,146,.3)",
      filter: "blur(0.05px)",
      animation: "bounceShadow 0.6s ease-in infinite alternate",
      animationDelay: "calc(var(--delay, 0) * 0.2s)",
    },
  },
  variants: {
    size: {
      sm: {
        root: {
          height: "6px",
        },
        ball: {
          size: "2px",
          "--bounce-depth": "2px",
        },
        shadow: {
          height: "1px",
          width: "2px",
        },
      },
      md: {
        root: {
          height: "10px",
        },
        ball: {
          size: "4px",
          "--bounce-depth": "3px",
        },
        shadow: {
          height: "2px",
          width: "4px",
        },
      },
      lg: {
        root: {
          height: "20px",
        },
        ball: {
          size: "8px",
          "--bounce-depth": "6px",
        },
        shadow: {
          height: "4px",
          width: "8px",
        },
      },
    },
    delay: {
      0: {},
      1: {
        root: {
          "--delay": "1",
        },
      },
      2: {
        root: {
          "--delay": "2",
        },
      },
    },
    color: {
      dark: {
        ball: {
          backgroundColor: "primary",
        },
      },
      light: {
        ball: {
          backgroundColor: "white",
        },
      },
    },
    shown: {
      false: {
        root: {
          display: "none",
        },
      },
    },
  },
  defaultVariants: {
    size: "md",
    delay: 0,
    shown: true,
  },
});
