import { Children, type ReactNode, isValidElement } from "react";

import { Feedback, type FeedbackElement } from "./Feedback";
import { Tooltip, type TooltipElement } from "./Tooltip";

export type FieldDecorators = Array<FeedbackElement | TooltipElement>;

function isFeedbackElement(child: ReactNode): child is FeedbackElement {
  return isValidElement(child) && child.type === Feedback;
}

function isTooltipElement(child: ReactNode): child is TooltipElement {
  return isValidElement(child) && child.type === Tooltip;
}

export function splitTooltipElement(
  children: ReactNode,
): [TooltipElement | null, ReactNode[]] {
  const childrenArray = Children.toArray(children);
  const tooltip = childrenArray.find(isTooltipElement);
  const other = childrenArray.filter((child) => !isTooltipElement(child));
  return [tooltip ?? null, other];
}

export function splitFieldDecorators(
  children: ReactNode,
): [FieldDecorators, ReactNode[]] {
  const childrenArray = Children.toArray(children);
  const { decorators, other } = childrenArray.reduce(
    (acc: { decorators: FieldDecorators; other: ReactNode[] }, child) => {
      if (isFeedbackElement(child) || isTooltipElement(child)) {
        acc.decorators.push(child);
      } else {
        acc.other.push(child);
      }
      return acc;
    },
    {
      decorators: [],
      other: [],
    },
  );
  return [decorators, other];
}

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
export const FieldDecorators = {
  Feedback,
  Tooltip,
};
