import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  type JSX,
} from "react";

import { t } from "@lingui/macro";
import {
  ejectAxiosInterceptor,
  registerAxiosInterceptor,
} from "@mobsuccess-devops/react-data-context";
import { useToasters } from "@mobsuccess-devops/react-ui/_PandaArk";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import {
  signIn as cognitoSignin,
  confirmSignIn,
  fetchAuthSession,
} from "aws-amplify/auth";
import axios, { type AxiosError } from "axios";
import { jwtDecode } from "jwt-decode";

import { useSessionStorage } from "../../../features/storage";
import {
  AuthActionEnum,
  AuthContext,
  isAuthAction,
  type AuthContextType,
  type PatSession,
  type CognitoUserSession,
  useForgotPassword,
  useResetPassword,
  synchPhpSessionWithCognitoStorage,
  useSignout,
} from "../../../public/auth/auth";
import { axiosGet } from "../../../public/fetch/query";
import type { BusinessUnits } from "../../../types/enums";
import AuthAction from "../AuthAction";

type AuthProviderProps = {
  children: JSX.Element;
  googleSignupUrl?: URL;
  forgotPasswordUrl: URL;
  googleClientId?: string;
  businessUnit: BusinessUnits;
  userPoolsWebClientId: string;
  onBearerRefresh?: (bearer: string) => void;
};

const cognitoAuthSessionKey = "cognito.auth.session";

function AuthProvider({
  children,
  businessUnit,
  googleClientId,
  googleSignupUrl,
  onBearerRefresh,
  userPoolsWebClientId,
}: AuthProviderProps): JSX.Element | null {
  const { toast } = useToasters();
  const [, setAutoLoginGoogle] = useSessionStorage("autoLoginGoogle");
  const [loginStatus, setLoginStatus] = useState<"idle" | "loading">("idle");
  const [pat, setPat] = useState<string | null>(null);
  const [profile, setProfile] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const [authAction, setAuthAction] = useState<AuthActionEnum | null>(() => {
    const search = new URLSearchParams(window.location.search);
    const action = search.get("action");
    if (!isAuthAction(action)) {
      return AuthActionEnum.SignIn;
    }
    return action;
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [cognitoAuthSessionKey],
    });
  }, [queryClient]);

  const { mutateAsync: signOut } = useSignout({
    postSignout(options) {
      setAutoLoginGoogle(false);
      invalidateQuery();
      if (options?.redirect) {
        window.location.assign(options.redirect);
      }
    },
  });

  const {
    isLoading,
    isFetching,
    data: sessionCognito,
  } = useQuery({
    queryKey: [cognitoAuthSessionKey, userPoolsWebClientId],
    async queryFn() {
      await synchPhpSessionWithCognitoStorage(userPoolsWebClientId);
      const action = new URLSearchParams(window.location.search).get("action");
      if (action === AuthActionEnum.SignOut) {
        await signOut({});
        return null;
      }

      const session = await fetchAuthSession({
        forceRefresh: true,
      });

      if (!session || !session.tokens?.accessToken) {
        return null;
      }

      if (session.tokens.accessToken.payload.exp) {
        const expiresIn =
          session.tokens.accessToken.payload.exp * 1000 - Date.now();
        setTimeout(() => {
          invalidateQuery();
        }, expiresIn);
      }

      const accessToken = session.tokens.accessToken
        .toString()
        .replace(/^jwt:/, "");

      try {
        await axiosGet({
          entity: "auth",
          verb: "login",
          token: `Bearer jwt:${accessToken}`,
        });
        onBearerRefresh?.(`Bearer jwt:${accessToken}`);
      } catch (error) {
        await signOut({});
        throw error;
      }

      return session;
    },
  });

  const sessionPat: PatSession | null =
    pat && profile ? { pat, profile } : null;
  const session: CognitoUserSession | PatSession | null =
    sessionCognito || sessionPat;

  const jwt = useMemo(() => {
    const session = sessionCognito;
    if (!session || !session.tokens) {
      return null;
    }
    const accessToken = session.tokens.accessToken
      .toString()
      .replace(/^jwt:/, "");
    return `Bearer jwt:${accessToken}`;
  }, [sessionCognito]);

  const googleSignIn = useCallback<AuthContextType["googleSignIn"]>(
    async ({ credential }) => {
      try {
        setLoginStatus("loading");
        setAutoLoginGoogle(false);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const { email } = jwtDecode(credential) as { email: string };
        await cognitoSignin({
          username: email,
          options: {
            authFlowType: "CUSTOM_WITHOUT_SRP",
          },
        });
        const answer = await confirmSignIn({
          challengeResponse: credential,
          options: {
            clientMetadata: { authType: "GOOGLE" },
          },
        });
        if (!answer.isSignedIn) {
          throw new Error("Something went wrong while signing in");
        }
      } catch (error) {
        signOut({});
        if (
          error instanceof Error &&
          error.name === "UserNotFoundException" &&
          googleSignupUrl
        ) {
          googleSignupUrl.searchParams.set("url", window.location.href);
          window.location.href = googleSignupUrl.toString();
          return;
        }
        toast.error({
          title: t`#Auth.AuthProvider.authenticationFailed`,
          description: t`#Auth.AuthProvider.googleAuthenticationFailed`,
        });
      } finally {
        window._msAuthBroadcastChannel?.postMessage("signin");
        invalidateQuery();
        setLoginStatus("idle");
      }
    },
    [toast, googleSignupUrl, invalidateQuery, setAutoLoginGoogle, signOut],
  );

  const signInWithPat = useCallback<AuthContextType["signInWithPat"]>(
    async ({ pat, profile }: { pat: string; profile: string }) => {
      setPat(pat);
      setProfile(profile);
    },
    [],
  );

  const signIn = useCallback<AuthContextType["signIn"]>(
    async (email, password) => {
      try {
        setLoginStatus("loading");
        setAutoLoginGoogle(false);
        const { isSignedIn } = await cognitoSignin({
          username: email,
          options: {
            authFlowType: "CUSTOM_WITHOUT_SRP",
          },
        });
        if (isSignedIn) {
          return;
        }
        const answer = await confirmSignIn({
          challengeResponse: password,
          options: {
            clientMetadata: { authType: "PASSWORD" },
          },
        });
        if (!answer.isSignedIn) {
          throw new Error("Something went wrong while signing in");
        }
      } catch {
        toast.error({
          title: t`#Auth.AuthProvider.authenticationFailed`,
          description: t`#Auth.AuthProvider.passwordAuthenticationFailed`,
        });
        signOut({});
      } finally {
        window._msAuthBroadcastChannel?.postMessage("signin");
        invalidateQuery();
        setLoginStatus("idle");
      }
    },
    [toast, invalidateQuery, setAutoLoginGoogle, signOut],
  );

  const { mutateAsync: forgotPassword } = useForgotPassword();

  const { mutateAsync: resetPassword } = useResetPassword();

  const handleChangeAuthAction = useCallback(
    (action: AuthActionEnum, state?: object) => {
      setAuthAction(action);
      const searchString =
        action === AuthActionEnum.SignIn ? "" : `?action=${action}`;
      window.history.replaceState(
        state ?? {},
        "",
        `${window.location.pathname}${searchString}`,
      );
    },
    [setAuthAction],
  );

  useEffect(() => {
    const bc = window._msAuthBroadcastChannel;
    if (!bc) {
      return;
    }
    const listener = (message: MessageEvent) => {
      if (message.data === "signin") {
        handleChangeAuthAction(AuthActionEnum.SignIn);
        invalidateQuery();
      }
    };
    bc.addEventListener("message", listener);
    return () => {
      bc.removeEventListener("message", listener);
    };
  }, [handleChangeAuthAction, invalidateQuery]);

  useEffect(() => {
    const interceptError = async (error: AxiosError) => {
      if (error?.response?.status === 401) {
        await signOut({});
        return null;
      }
      throw error;
    };
    const rdcInterceptNumber = registerAxiosInterceptor(
      "response",
      (response) => response,
      interceptError,
    );
    const interceptNumber = axios.interceptors.response.use(
      (response) => response,
      interceptError,
    );

    return () => {
      ejectAxiosInterceptor("response", rdcInterceptNumber);
      axios.interceptors.response.eject(interceptNumber);
    };
  }, [signOut]);

  useEffect(() => {
    const listener = (event: Event) => {
      if (!(event instanceof CustomEvent)) {
        return;
      }
      signOut(event.detail);
    };
    window.addEventListener("sign-out", listener);
    return () => {
      window.removeEventListener("sign-out", listener);
    };
  }, [signOut]);

  const contextValue = useMemo(
    () => ({
      session: session || null,
      jwt,
      pat,
      bearer: jwt || `Bearer ${pat}`,
      isLoggedIn: !!jwt || !!pat,
      signInWithPat,
      signIn,
      signOut,
      googleSignIn,
      resetPassword,
      forgotPassword,
    }),
    [
      session,
      jwt,
      pat,
      signInWithPat,
      signIn,
      signOut,
      googleSignIn,
      resetPassword,
      forgotPassword,
    ],
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {session ? (
        children
      ) : (
        <AuthAction
          action={authAction}
          businessUnit={businessUnit}
          googleClientId={googleClientId}
          onChangeAuthAction={handleChangeAuthAction}
          isLoading={loginStatus === "loading" || isLoading || isFetching}
        />
      )}
    </AuthContext.Provider>
  );
}

export default memo(AuthProvider);
