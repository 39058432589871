import { useCallback, useMemo, type JSX } from "react";

import { IconGlobe } from "@mobsuccess-devops/icons";
import {
  Select,
  type SelectValueChangeDetails,
} from "@mobsuccess-devops/react-ui/_PandaArk";

import { useLanguageContext } from "../../../public/i18n";
import { APP_LOCALES } from "../../../public/i18n/i18n";
import languagesMap from "../../../public/i18n/languages-map";

export function LanguageSelect(): JSX.Element | null {
  const options = useMemo(() => {
    return APP_LOCALES.map((language) => ({
      value: language,
      label: languagesMap.get(language) ?? "?",
    })).sort((a, b) => a.label.localeCompare(b.label));
  }, []);

  const { onLanguageChange, language } = useLanguageContext();

  const handleValueChange = useCallback(
    ({ value }: SelectValueChangeDetails) => {
      const [lang] = value;
      onLanguageChange(lang);
    },
    [onLanguageChange],
  );

  const defaultValue = useMemo(() => {
    return [language];
  }, [language]);

  if (!APP_LOCALES.length) {
    return null;
  }

  return (
    <Select.Root
      onValueChange={handleValueChange}
      items={options}
      defaultValue={defaultValue}
      isClearable={false}
    >
      <Select.Icon>
        <IconGlobe />
      </Select.Icon>
    </Select.Root>
  );
}
