/* eslint-disable @mobsuccess-devops/mobsuccess/variables */
import type { JSX, ReactNode } from "react";

import {
  Combobox,
  type ComboboxTriggerProps,
  useComboboxContext,
} from "@ark-ui/react";
import { IconArrowDouble } from "@mobsuccess-devops/icons";
import { css, cx } from "@mobsuccess-devops/styled-system/css";
import { Box } from "@mobsuccess-devops/styled-system/jsx";
import { hstack } from "@mobsuccess-devops/styled-system/patterns";

import type { SelectItemGroup, SelectItems } from "./Select";

type SelectItemStartProps = {
  children: ReactNode;
};

export function SelectItemStart({
  children,
}: SelectItemStartProps): JSX.Element {
  return (
    <Box order={1} center>
      {children}
    </Box>
  );
}

type SelectItemEndProps = {
  children: ReactNode;
};

export function SelectItemEnd({ children }: SelectItemEndProps): JSX.Element {
  return (
    <Box order={3} center>
      {children}
    </Box>
  );
}

type SelectIconProps = {
  children: JSX.Element;
};

export function SelectIcon({ children }: SelectIconProps): JSX.Element {
  return (
    <div
      className={css({
        center: true,
        color: "interface",
        _childSvg: {
          size: "sm",
        },
      })}
    >
      {children}
    </div>
  );
}

type SelectTriggerProps = ComboboxTriggerProps;

export function SelectTrigger({
  className,
  ...props
}: SelectTriggerProps): JSX.Element {
  return (
    <Combobox.Trigger
      className={cx(className, css({ cursor: "pointer" }))}
      {...props}
    />
  );
}

export function SelectAddonTrigger(): JSX.Element {
  const comboboxContext = useComboboxContext();
  return (
    <Combobox.Control asChild>
      <Combobox.Trigger
        className={css({
          ...hstack.raw({ gap: "sm" }),
          height: "full",
          center: true,
          backgroundColor: "interface.200",
          color: "interface",
          cursor: "pointer",
          paddingX: "sm",
          boxShadow: "unset",
          border: "none",
        })}
      >
        {comboboxContext.valueAsString}
        <IconArrowDouble size="xs" />
      </Combobox.Trigger>
    </Combobox.Control>
  );
}

export function defaultFilter(
  item: SelectItems[number],
  search: string,
  group?: SelectItemGroup,
): boolean {
  if (!search) {
    return true;
  }
  if (item.type === "group") {
    return (
      item.label.toLowerCase().includes(search.toLowerCase()) ||
      item.items.some((item) => defaultFilter(item, search))
    );
  }
  return (
    item.label.toLowerCase().includes(search.toLowerCase()) ||
    (group?.label.toLowerCase().includes(search.toLowerCase()) ?? false)
  );
}
