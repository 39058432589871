export default new Map(
  Object.entries({
    aa: "Afar",
    ab: "Аҧсуа",
    af: "Afrikaans",
    ak: "Akana",
    am: "አማርኛ",
    an: "Aragonés",
    ar: "العربية",
    as: "অসমীয়া",
    av: "Авар",
    ay: "Aymar",
    az: "Azərbaycanca / آذربايجان",
    ba: "Башҡорт",
    be: "Беларуская",
    bg: "Български",
    bi: "Bislama",
    bm: "Bamanankan",
    bn: "বাংলা",
    bo: "བོད་ཡིག / Bod skad",
    br: "Brezhoneg",
    bs: "Bosanski",
    ca: "Català",
    ce: "Нохчийн",
    ch: "Chamoru",
    co: "Corsu",
    cr: "Nehiyaw",
    cs: "Česky",
    cu: "словѣньскъ / slověnĭskŭ",
    cv: "Чăваш",
    cy: "Cymraeg",
    da: "Dansk",
    de: "Deutsch",
    dv: "ދިވެހިބަސް",
    dz: "ཇོང་ཁ",
    ee: "Ɛʋɛ",
    el: "Ελληνικά",
    en: "English",
    eo: "Esperanto",
    es: "Español",
    et: "Eesti",
    eu: "Euskara",
    fa: "فارسی",
    ff: "Fulfulde",
    fi: "Suomi",
    fj: "Na Vosa Vakaviti",
    fo: "Føroyskt",
    fr: "Français",
    fy: "Frysk",
    ga: "Gaeilge",
    gd: "Gàidhlig",
    gl: "Galego",
    gn: "Avañe'ẽ",
    gu: "ગુજરાતી",
    gv: "Gaelg",
    ha: "هَوُسَ",
    he: "עברית",
    hi: "हिन्दी",
    ho: "Hiri Motu",
    hr: "Hrvatski",
    ht: "Krèyol ayisyen",
    hu: "Magyar",
    hy: "Հայերեն",
    hz: "Otsiherero",
    ia: "Interlingua",
    id: "Bahasa Indonesia",
    ie: "Interlingue",
    ig: "Igbo",
    ii: "ꆇꉙ / 四川彝语",
    ik: "Iñupiak",
    io: "Ido",
    is: "Íslenska",
    it: "Italiano",
    iu: "ᐃᓄᒃᑎᑐᑦ",
    ja: "日本語",
    jv: "Basa Jawa",
    ka: "ქართული",
    kg: "KiKongo",
    ki: "Gĩkũyũ",
    kj: "Kuanyama",
    kk: "Қазақша",
    kl: "Kalaallisut",
    km: "ភាសាខ្មែរ",
    kn: "ಕನ್ನಡ",
    ko: "한국어",
    kr: "Kanuri",
    ks: "कश्मीरी / كشميري",
    ku: "Kurdî / كوردی",
    kv: "Коми",
    kw: "Kernewek",
    ky: "Kırgızca / Кыргызча",
    la: "Latina",
    lb: "Lëtzebuergesch",
    lg: "Luganda",
    li: "Limburgs",
    ln: "Lingála",
    lo: "ລາວ / Pha xa lao",
    lt: "Lietuvių",
    lv: "Latviešu",
    mg: "Malagasy",
    mh: "Kajin Majel / Ebon",
    mi: "Māori",
    mk: "Македонски",
    ml: "മലയാളം",
    mn: "Монгол",
    mr: "मराठी",
    ms: "Bahasa Melayu",
    mt: "bil-Malti",
    my: "Myanmasa",
    na: "Dorerin Naoero",
    nd: "Sindebele",
    ne: "नेपाली",
    ng: "Oshiwambo",
    nl: "Nederlands",
    nn: "Norsk (nynorsk)",
    no: "Norsk (bokmål / riksmål)",
    nr: "isiNdebele",
    nv: "Diné bizaad",
    ny: "Chi-Chewa",
    oc: "Occitan",
    oj: "ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin",
    om: "Oromoo",
    or: "ଓଡ଼ିଆ",
    os: "Иронау",
    pa: "ਪੰਜਾਬੀ / पंजाबी / پنجابي",
    pi: "Pāli / पाऴि",
    pl: "Polski",
    ps: "پښتو",
    pt: "Português",
    qu: "Runa Simi",
    rm: "Rumantsch",
    rn: "Kirundi",
    ro: "Română",
    ru: "Русский",
    rw: "Kinyarwandi",
    sa: "संस्कृतम्",
    sc: "Sardu",
    sd: "सिनधि",
    se: "Davvisámegiella",
    sg: "Sängö",
    si: "සිංහල",
    sk: "Slovenčina",
    sl: "Slovenščina",
    sm: "Gagana Samoa",
    sn: "chiShona",
    so: "Soomaaliga",
    sq: "Shqip",
    sr: "Српски",
    ss: "SiSwati",
    st: "Sesotho",
    su: "Basa Sunda",
    sv: "Svenska",
    sw: "Kiswahili",
    ta: "தமிழ்",
    te: "తెలుగు",
    tg: "Тоҷикӣ",
    th: "ไทย / Phasa Thai",
    ti: "ትግርኛ",
    tk: "Туркмен / تركمن",
    tl: "Tagalog",
    tn: "Setswana",
    to: "Lea Faka-Tonga",
    tr: "Türkçe",
    ts: "Xitsonga",
    tt: "Tatarça",
    tw: "Twi",
    ty: "Reo Mā`ohi",
    ug: "Uyƣurqə / ئۇيغۇرچە",
    uk: "Українська",
    ur: "اردو",
    uz: "Ўзбек",
    ve: "Tshivenḓa",
    vi: "Việtnam",
    vo: "Volapük",
    wa: "Walon",
    wo: "Wollof",
    xh: "isiXhosa",
    yi: "ייִדיש",
    yo: "Yorùbá",
    za: "Cuengh / Tôô / 壮语",
    zh: "中文",
    zu: "isiZulu",
    nb: "Norsk (bokmål)",
  }),
);
