import { comboboxAnatomy } from "@ark-ui/anatomy";
import { sva } from "@mobsuccess-devops/styled-system/css";

export const styles = sva({
  slots: comboboxAnatomy.extendWith("tag", "tagPreview").keys(),
  base: {
    root: {
      gap: "sm",
      width: "full",
      position: "relative",
    },
    control: {
      backgroundColor: "white",
      borderRadius: "md",
      border: "1px solid",
      borderColor: "#959EC9",
      boxShadow:
        "0 0 0 0 color-mix(in srgb, token(colors.primary) 30%, transparent)",
      transition: "box-shadow 0.2s ease-in-out",
      _disabled: {
        pointerEvents: "none",
        opacity: 0.5,
      },
      _hover: {
        borderColor: "interface.400",
      },
      _focus: {
        borderColor: "primary",
        boxShadow:
          "0 0 0 4px color-mix(in srgb, token(colors.primary) 30%, transparent)",
      },
    },
    trigger: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "transparent",
      border: "none",
      paddingX: "sm",
      height: "40px",
      alignItems: "center",
      width: "100%",
    },
    clearTrigger: {
      display: "flex",
      cursor: "pointer",
      padding: "xs",
      backgroundColor: "transparent",
      border: "none",
      pointerEvents: "auto",
    },
    tag: {
      display: "var(--display, flex)",
    },
    tagPreview: {
      borderRadius: "sm",
      backgroundColor: "primary.200",
      paddingX: "sm",
      whiteSpace: "nowrap",
      maxWidth: "110px",
      display: "flex",
      alignItems: "center",
      gap: "xs",
      "&[data-removeable=true]": {
        paddingRight: "0",
      },
      "& > p": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  },
  variants: {
    addon: {
      true: {
        root: {
          height: "full",
        },
      },
    },
    size: {
      sm: {
        tagPreview: {
          height: "md",
          "& > p": {
            fontSize: "12px!",
          },
        },
      },
      md: {
        tagPreview: {
          height: "lg",
          "& > p": {
            fontSize: "14px",
          },
        },
      },
      lg: {
        tagPreview: {
          height: "lg",
          "& > p": {
            fontSize: "lg",
          },
        },
      },
      xl: {
        tagPreview: {
          height: "40px",
          "& > p": {
            fontSize: "20px",
          },
        },
      },
    },
  },
});
