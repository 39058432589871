import { useMemo } from "react";

type Variants = Record<string, string | RegExp>;
export type Variant<Payload> = {
  name: string;
  payload: Payload;
};

export function useVariant<Payload extends object>(
  variants: Variants,
  transform: (variant: string, match: Array<string>) => Payload | undefined,
  variant?: string,
): Partial<Variant<Payload>> {
  return useMemo(() => {
    if (!variant) {
      return {};
    }
    for (const [name, regexp] of Object.entries(variants)) {
      const rgx = new RegExp(regexp);
      if (!rgx.test(variant)) {
        continue;
      }
      const executed = rgx.exec(variant);
      if (!executed) {
        continue;
      }
      const [, ...match] = executed;
      const payload = transform(name, match);
      return {
        name,
        payload,
      };
    }
    return {};
  }, [variant, variants, transform]);
}
