import type { JSX } from "react";

import { Trans } from "@lingui/macro";
import { Loader } from "@mobsuccess-devops/react-ui/_PandaArk";

import { useUnsafeQueryNavbar } from "../../public/services/user/navbar";

type EnsureUserProps = {
  children: JSX.Element;
};

export function EnsureUser({ children }: EnsureUserProps): JSX.Element {
  const query = useUnsafeQueryNavbar();

  if (query.isLoading) {
    return (
      <Loader.Block>
        <Loader.Ellipsis />
        <Loader.Description>
          <Trans>#EnsureUser.loading-user-infos</Trans>
        </Loader.Description>
      </Loader.Block>
    );
  }

  if (query.isError) {
    return (
      <Loader.Block>
        <Loader.Ellipsis />
        <Loader.Description>
          <Trans>#EnsureUser.error-loading-user-infos</Trans>
        </Loader.Description>
      </Loader.Block>
    );
  }

  return children;
}
