import { cva } from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";

const mutedStyle = cva({
  base: {
    textStyle: "muted",
    color: "interface.400",
  },
});

export const Muted = styled("p", mutedStyle);
