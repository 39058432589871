import { type JSX, type ReactNode, memo, useRef } from "react";

import { AtomStore } from "../../features/atom-store";

type AtomStoreProviderBaseProps = {
  children?: ReactNode;
  initialStore?: Record<string, unknown>;
};

function AtomStoreProviderBase({
  children,
  initialStore,
}: AtomStoreProviderBaseProps): JSX.Element {
  const store = useRef(new AtomStore(initialStore));

  return (
    <AtomStore.Provider value={store.current}>{children}</AtomStore.Provider>
  );
}

const MemoizedAtomStoreProviderBase = memo(AtomStoreProviderBase);
export { MemoizedAtomStoreProviderBase as AtomStoreProviderBase };
