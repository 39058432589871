import {
  type FormEventHandler,
  useCallback,
  useMemo,
  useState,
  type JSX,
} from "react";

import { t, Trans } from "@lingui/macro";
import { DarkButton } from "@mobsuccess-devops/react-ui/Button";
import { Container } from "@mobsuccess-devops/react-ui/Container";
import { FieldLayout } from "@mobsuccess-devops/react-ui/Form";
import { FieldDecorators } from "@mobsuccess-devops/react-ui/Form/Field/decorators";
import { Typography } from "@mobsuccess-devops/react-ui/Typography";
import { Input } from "@mobsuccess-devops/react-ui/_PandaArk";
import { css } from "@mobsuccess-devops/styled-system/css";

import styled, { css as styledCss, useTheme } from "styled-components";

import { AuthActionEnum, useAuth } from "../../../public/auth/auth";

import { EmailSent } from "./EmailSent";

const FormUI = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 24px;
`;

const paswordLostCss = styledCss`
  text-decoration: underline;
  cursor: pointer;
`;

enum EmailError {
  None = "none",
  Empty = "empty",
}

type ForgotPasswordProps = {
  onChangeAuthAction: (action: AuthActionEnum, state?: object) => void;
};

function ForgotPassword({
  onChangeAuthAction,
}: ForgotPasswordProps): JSX.Element {
  const { palette } = useTheme();
  const { forgotPassword } = useAuth();

  const [error, setError] = useState(EmailError.None);

  const [sent, setSent] = useState<string | false>(false);

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault();
      const email = e.currentTarget.email.value;

      if (!email) {
        setError(EmailError.Empty);
        return;
      }

      setError(EmailError.None);

      await forgotPassword({
        email,
      });
      setSent(email);
    },
    [forgotPassword],
  );

  const handleRetry = useCallback(() => {
    setSent(false);
  }, []);

  const handleBackToLogin = useCallback(() => {
    onChangeAuthAction(AuthActionEnum.SignIn);
  }, [onChangeAuthAction]);

  const { state, caption } = useMemo(() => {
    if (error === EmailError.Empty) {
      return {
        state: "error" as const,
        caption: t`#Auth.ForgotPassword.email-error.empty`,
      };
    }
    return {};
  }, [error]);

  if (sent) {
    return (
      <EmailSent
        email={sent}
        onRetry={handleRetry}
        onBackToLogin={handleBackToLogin}
      />
    );
  }

  const fieldDecorators =
    state === "error" && caption
      ? [
          <FieldDecorators.Feedback state={state} position="end">
            {caption}
          </FieldDecorators.Feedback>,
        ]
      : [];

  return (
    <FormUI onSubmit={handleSubmit} id="auth-form">
      <Typography
        as="span"
        variant="md-light"
        color={palette.interface.lighter[100]}
      >
        <Trans>#Auth.ForgotPassword.forgotPasswordMessage</Trans>
      </Typography>
      <FieldLayout
        name="email"
        label={t`#Auth.ForgotPassword.email`}
        required
        className={css({
          width: "100%",
        })}
        decorators={fieldDecorators}
      >
        <Input.Root
          required
          size="md"
          name="email"
          type="email"
          state={state}
          autoComplete="email"
          placeholder={t`#Auth.ForgotPassword.emailPlaceholder`}
          defaultValue={
            window.history.state?.email ??
            new URLSearchParams(window.location.search).get("email")
          }
        />
      </FieldLayout>
      <Container
        flexDirection="column"
        gap="12px"
        width="100%"
        alignItems="center"
      >
        <DarkButton fill size="lg" formNoValidate form="auth-form">
          <Trans>#Auth.ForgotPassword.submit</Trans>
        </DarkButton>
        <Typography
          as="span"
          styled={paswordLostCss}
          color={palette.primary.base}
          onClick={handleBackToLogin}
        >
          <Trans>#Auth.ForgotPassword.back</Trans>
        </Typography>
      </Container>
    </FormUI>
  );
}

export default ForgotPassword;
