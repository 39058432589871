import { useMemo } from "react";

import type { SelectItems } from "./Select";

export function useSelectItems<T>(
  list: Array<T>,
  mapper: (item: T) => SelectItems[number],
): SelectItems {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => list.map(mapper), [list]);
}
