import {
  forwardRef,
  useCallback,
  type ForwardedRef,
  type JSX,
  type MouseEventHandler,
  useRef,
} from "react";

import { WhacamoleButton, type WhacamoleButtonProps } from "./WhacamoleButton";

export type ButtonProps = WhacamoleButtonProps;

function Button(
  { children, onClick, ...props }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
): JSX.Element {
  const lastClickRef = useRef<number>(0);

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      const now = Date.now();
      const elapsed = now - lastClickRef.current;
      if (elapsed < 2e3 || e.detail > 1) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      lastClickRef.current = now;
      if (onClick) {
        onClick(e);
      }
    },
    [onClick],
  );
  return (
    <WhacamoleButton {...props} ref={ref} onClick={handleClick}>
      {children}
    </WhacamoleButton>
  );
}

const MemoizedButton = forwardRef(Button);
export { MemoizedButton as Button };
