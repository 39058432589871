import {
  memo,
  type ForwardedRef,
  useCallback,
  forwardRef,
  type JSX,
} from "react";

import styled, { css, useTheme } from "styled-components";

import { useVariant, type Variant } from "../../features/variants/useVariant";
import type { NativeProps } from "../../types/native-props";
import { type Breakpoints, theme } from "../Theme/theme";

type ContainerUIProps = {
  $width?: string;
  $height?: string;
  $flex?: string;
  $flexDirection?: "row" | "column" | "row-reverse" | "column-reverse";
  $overflow?: "hidden" | "visible" | "scroll" | "auto";
  $justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  $alignItems?: "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
  $backgroundColor?: string;
  $gap?: string;
  $padding?: string;
  $variantInfos: Partial<Variant<{ size: string; breakpoint: string }>>;
};

const widgetVariantCss = css<ContainerUIProps>`
  border-radius: ${({ theme }) => theme.widgetRadius};
  box-shadow: ${({ theme }) => theme.widgetShadow};
  overflow: hidden;
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ?? theme.colors.white};
  width: ${({ $variantInfos }) => $variantInfos.payload?.size};
  @media screen and (max-width: ${({ $variantInfos }) =>
      $variantInfos.payload?.breakpoint}) {
    width: 100%;
  }
`;

const overlayVariantCss = css<ContainerUIProps>`
  z-index: 1000;
`;

const ContainerUI = styled.div<ContainerUIProps>`
  position: relative;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  display: flex;
  flex: ${({ $flex }) => $flex};
  flex-direction: ${({ $flexDirection }) => $flexDirection};
  justify-content: ${({ $justifyContent }) => $justifyContent};
  align-items: ${({ $alignItems }) => $alignItems};
  background: ${({ $backgroundColor }) => $backgroundColor ?? $backgroundColor};
  gap: ${({ $gap }) => $gap ?? $gap};
  padding: ${({ $padding }) => $padding};
  overflow: ${({ $overflow }) => $overflow};
  ${({ $variantInfos }) => $variantInfos.name === "widget" && widgetVariantCss}
  ${({ $variantInfos }) =>
    $variantInfos.name === "overlay" && overlayVariantCss}
`;

ContainerUI.displayName = "ContainerUI";

const containerVariants = {
  widget: `^widget-(sm|md|lg)-(${Object.keys(theme.breakpoints).join("|")})$`,
  overlay: /^overlay$/,
};

type ContainerProps = {
  width?: string;
  height?: string;
  flex?: string;
  flexDirection?: "row" | "column" | "row-reverse" | "column-reverse";
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  alignItems?: "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
  backgroundColor?: string;
  gap?: string;
  variant?: "overlay" | `widget-${"sm" | "md" | "lg"}-${keyof Breakpoints}`;
  overflow?: "auto" | "hidden" | "scroll" | "visible";
  padding?: string;
} & NativeProps<"div">;

function Container(
  {
    children,
    width = "auto",
    height = "auto",
    flex,
    flexDirection,
    justifyContent,
    alignItems,
    overflow,
    variant,
    backgroundColor,
    padding,
    gap,
    draggable = false,
    ...intrinsicProps
  }: ContainerProps,
  ref?: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  const theme = useTheme();

  const transformVariant = useCallback(
    (variant: string, match: Array<string>) => {
      if (variant === "widget") {
        const [size, breakpoint] = match;
        return {
          size: size === "sm" ? "33%" : size === "md" ? "50%" : "100%",
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          breakpoint: theme.breakpoints[breakpoint as keyof Breakpoints],
        };
      }
      return undefined;
    },
    [theme],
  );

  const variantInfos = useVariant<{ size: string; breakpoint: string }>(
    containerVariants,
    transformVariant,
    variant,
  );

  return (
    <ContainerUI
      ref={ref}
      $width={width}
      $height={height}
      $flex={flex}
      $flexDirection={flexDirection}
      $justifyContent={justifyContent}
      $alignItems={alignItems}
      $backgroundColor={backgroundColor}
      $variantInfos={variantInfos}
      $overflow={overflow}
      $gap={gap}
      $padding={padding}
      draggable={draggable}
      {...intrinsicProps}
    >
      {children}
    </ContainerUI>
  );
}

const MemoizedContainer = memo(forwardRef(Container));
export { MemoizedContainer as Container };
