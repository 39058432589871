import type { DefaultTheme } from "styled-components";

export type InputTheme = {
  height: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
  padding: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
  fontSize: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
  border: {
    default: (theme: DefaultTheme) => string;
    hover: (theme: DefaultTheme) => string;
    focus: (theme: DefaultTheme) => string;
    filled: (theme: DefaultTheme) => string;
    disabled: (theme: DefaultTheme) => string;
    error: (theme: DefaultTheme) => string;
    success: (theme: DefaultTheme) => string;
    warning: (theme: DefaultTheme) => string;
  };
  borderRadius: {
    compact: string;
    default: string;
  };
};

export const input: InputTheme = {
  height: {
    sm: "28px",
    md: "40px",
    lg: "48px",
    xl: "56px",
  },
  fontSize: {
    sm: "12px",
    md: "14px",
    lg: "16px",
    xl: "20px",
  },
  padding: {
    sm: "6px 8px",
    md: "10px 12px",
    lg: "12px 16px",
    xl: "12px 20px",
  },
  borderRadius: {
    compact: "4px",
    default: "6px",
  },
  border: {
    default(theme: DefaultTheme) {
      return theme.palette.interface.lighter[200] ?? "transparent";
    },
    hover(theme: DefaultTheme) {
      return theme.palette.interface.lighter[100] ?? "transparent";
    },
    focus(theme: DefaultTheme) {
      return theme.palette.primary.lighter[200] ?? "transparent";
    },
    filled(theme: DefaultTheme) {
      return theme.palette.interface.lighter[200] ?? "transparent";
    },
    disabled(theme: DefaultTheme) {
      return `${theme.palette.interface.lighter[100]}`;
    },
    error(theme: DefaultTheme) {
      return theme.palette.danger.base ?? "transparent";
    },
    warning(theme: DefaultTheme) {
      return theme.palette.warning.base ?? "transparent";
    },
    success(theme: DefaultTheme) {
      return theme.palette.success.base ?? "transparent";
    },
  },
};
