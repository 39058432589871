import { useQuery, type UseQueryResult } from "@tanstack/react-query";

import axios, { type AxiosPromise, type AxiosResponse } from "axios";

import { useAuth } from "../auth";

function getURI({
  entity,
  verb = "list",
  params,
}: {
  entity: string;
  verb?: string;
  params?: Record<string, string | number>;
}): string {
  return `${
    window._msApiEndpoint ?? import.meta.env.VITE_MS_API_ENDPOINT
  }/webservices/rest/${verb}-${entity}${
    params
      ? `?${Object.entries(params)
          .map(
            ([name, value]) =>
              `${encodeURIComponent(name)}=${encodeURIComponent(
                Array.isArray(value) ? JSON.stringify(value) : value,
              )}`,
          )
          .join("&")}`
      : ""
  }`;
}

const runningRequests: Record<string, AxiosPromise> = {};

export async function axiosGet<T>({
  entity,
  verb,
  token,
  params,
}: {
  entity: string;
  verb?: string;
  token?: string | null;
  params?: Record<string, string | number>;
}): Promise<AxiosResponse<T>> {
  const url = getURI({ entity, verb, params });
  if (await runningRequests[url]) {
    return await runningRequests[url];
  }
  const promise = axios(url, {
    method: "get",
    headers: {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      Authorization: token as string,
    },
  });
  runningRequests[url] = promise;
  const result = await promise;
  delete runningRequests[url];
  return result;
}

type getURIOptions = {
  entity: string;
  verb?: string;
  params?: Record<string, string | number>;
  token: string | null;
  body?: unknown;
};

export async function axiosPost<T = unknown, D = unknown>({
  entity,
  verb,
  params,
  token,
}: getURIOptions): Promise<AxiosResponse<T, D>> {
  const url = getURI({ entity, verb, params });
  if (typeof runningRequests[url] !== "undefined") {
    return await runningRequests[url];
  }
  const headers = token ? { Authorization: token } : undefined;
  const promise = axios({
    method: "post",
    url,
    headers: headers,
  });
  runningRequests[url] = promise;
  try {
    const result = await promise;
    delete runningRequests[url];
    return result;
  } catch (e) {
    delete runningRequests[url];
    throw e;
  }
}

export async function axiosPatch<T = unknown, D = unknown>({
  entity,
  verb,
  params,
  token,
  body,
}: getURIOptions): Promise<AxiosResponse<T, D>> {
  const url = getURI({ entity, verb, params });
  if (typeof runningRequests[url] !== "undefined") {
    return await runningRequests[url];
  }
  const headers = token ? { Authorization: token } : undefined;
  const promise = axios({
    method: "patch",
    url,
    headers: headers,
    data: body,
  });
  runningRequests[url] = promise;
  try {
    const result = await promise;
    delete runningRequests[url];
    return result;
  } catch (e) {
    delete runningRequests[url];
    throw e;
  }
}

export function useAxiosGet<T>({
  entity,
  verb,
  params,
}: {
  entity: string;
  verb?: string;
  token?: string | null;
  params?: Record<string, string | number>;
}): UseQueryResult<{
  body: T;
  headers: Record<string, string>;
}> {
  const { bearer } = useAuth();
  return useQuery({
    queryKey: [entity, verb, params, bearer],
    async queryFn() {
      const res = await axiosGet<T>({
        entity,
        verb,
        token: bearer,
        params,
      });
      return {
        body: res.data,
        headers: res.headers,
      };
    },
  });
}
