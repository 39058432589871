import axios, {
  type AxiosResponse,
  type AxiosError,
  type AxiosInstance,
} from "axios";

async function error(
  this: AxiosInstance,
  error: AxiosError,
): Promise<AxiosResponse> {
  const response = error.response;
  if (!response || response.status !== 504 || !response.config.retry) {
    throw error;
  }
  return (this ?? axios).request({
    ...response.config,
    retry: false,
  });
}

export const gatewayTimeout = {
  error,
};
