/* eslint-disable @mobsuccess-devops/mobsuccess/variables */
import type { ReactNode, JSX, ComponentPropsWithoutRef } from "react";

import { css, cx } from "@mobsuccess-devops/styled-system/css";

type InputPrefixProps = {
  className?: string;
  children?: ReactNode;
} & ComponentPropsWithoutRef<"div">;

export function InputPrefix({
  className,
  children,
  ...rest
}: InputPrefixProps): JSX.Element {
  return (
    <div className={cx(prefixCss, className)} {...rest}>
      {children}
    </div>
  );
}

type InputSuffixProps = {
  className?: string;
  children?: ReactNode;
} & ComponentPropsWithoutRef<"div">;

export function InputSuffix({
  className,
  children,
  ...rest
}: InputSuffixProps): JSX.Element {
  return (
    <div className={cx(suffixCss, className)} {...rest}>
      {children}
    </div>
  );
}

type InputLeadingAddonProps = {
  className?: string;
  children?: ReactNode;
} & ComponentPropsWithoutRef<"div">;

export function InputLeadingAddon({
  className,
  children,
  ...rest
}: InputLeadingAddonProps): JSX.Element {
  return (
    <div className={cx(leadingAddonCss, className)} {...rest}>
      {children}
    </div>
  );
}

type InputTrailingAddonProps = {
  className?: string;
  children?: ReactNode;
} & ComponentPropsWithoutRef<"div">;

export function InputTrailingAddon({
  className,
  children,
  ...rest
}: InputTrailingAddonProps): JSX.Element {
  return (
    <div className={cx(trailingAddonCss, className)} {...rest}>
      {children}
    </div>
  );
}

const prefixCss = css({
  order: 1,
  pointerEvents: "none",
  color: "interface",
  "&:has(~ div>input:placeholder-shown), &:has(~ input:placeholder-shown)": {
    color: "#BCC1DE",
  },
});

const suffixCss = css({
  order: 3,
  pointerEvents: "none",
  color: "interface",
  "&:has(~ div>input:placeholder-shown), &:has(~ input:placeholder-shown)": {
    color: "#BCC1DE",
  },
});

const leadingAddonCss = css({
  order: 0,
  height: "100%",
  marginLeft: "-sm",
  display: "flex",
  alignItems: "center",
});

const trailingAddonCss = css({
  order: 4,
  height: "100%",
  marginRight: "-sm",
});
