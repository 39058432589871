import { useEffect, type JSX } from "react";

import { useScript } from "../../../../features/scripts";
import { useAuth } from "../../../../public/auth/auth";

declare global {
  // this is an override for the global window interface
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    _msSignoutIframeUrl?: string;
    _msAuthBroadcastChannel?: BroadcastChannel;
    _signOutUrl?: string;
    _onGoogleSignIn?: (_: { credential: string }) => void;
  }
}

type GoogleSignInProps = {
  googleClientId: string;
  autoSelect?: boolean;
};

function GoogleSignIn({
  autoSelect = true,
  googleClientId,
}: GoogleSignInProps): JSX.Element {
  const { googleSignIn } = useAuth();
  useScript({
    id: "google-signin",
    src: "https://accounts.google.com/gsi/client",
  });

  useEffect(() => {
    window._onGoogleSignIn = googleSignIn;
    return () => {
      delete window._onGoogleSignIn;
    };
  }, [googleSignIn]);

  return (
    <div>
      <div
        id="g_id_onload"
        data-client_id={googleClientId}
        data-callback="_onGoogleSignIn"
        data-auto_select={autoSelect}
      />
      <div
        className="g_id_signin"
        data-type="standard"
        data-size="large"
        data-theme="outline"
        data-text="sign_in_with"
        data-shape="rectangular"
        data-logo_alignment="left"
      />
    </div>
  );
}

export default GoogleSignIn;
