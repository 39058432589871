import type { ReactNode, MouseEvent } from "react";

import styled, { css } from "styled-components";

import type { Sizes } from "../../types/sizes";
import type { PaletteColorsKeys } from "../Theme/theme";

function buttonHeight(size: Sizes): string {
  const mapper: Record<Sizes, string> = {
    sm: "32px",
    md: "40px",
    lg: "48px",
    xl: "56px",
  };
  return mapper[size];
}

function buttonSpacing(size: Sizes): string {
  const mapper: Record<Sizes, string> = {
    sm: "8px",
    md: "10px",
    lg: "14px",
    xl: "16px",
  };
  return mapper[size];
}

export type ButtonProps = {
  children: ReactNode;
  color?: PaletteColorsKeys;
  size?: Sizes;
  fill?: boolean;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const BaseButton = styled.button
  .attrs(({ size, color, fill }: ButtonProps) => ({
    $color: color ?? "primary",
    $size: size ?? "md",
    $fill: !!fill,
  }))
  .withConfig({
    shouldForwardProp: (prop) => !["fill"].includes(prop),
  })`
  display: inline-flex;
  align-items: center;
  ${({ $fill }) =>
    $fill
      ? css`
          width: 100%;
          justify-content: center;
        `
      : css`
          width: fit-content;
        `};
  height: ${({ $size }) => buttonHeight($size)};
  gap: ${({ $size }) => buttonSpacing($size)};
  padding: ${({ $size }) => buttonSpacing($size)};
  border-radius: 8px;
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: ${({ $size, theme }) => theme.typography.fontSize[$size]};
  font-weight: 500;
  line-height: ${({ $size, theme }) => theme.typography.lineHeight[$size]};
  transition: all 0.2s ease-in-out;
  svg {
    height: ${({ $size }) => ($size === "xl" ? "24px" : "20px")};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
