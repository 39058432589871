import { memo, type ReactNode, type JSX } from "react";

import { Container } from "@mobsuccess-devops/react-ui/Container";
import { Typography } from "@mobsuccess-devops/react-ui/Typography";

import styled, { css, useTheme } from "styled-components";

import { ReactComponent as FarlyIcon } from "../../../assets/farly-logo.svg";
import { ReactComponent as LeboncoinIcon } from "../../../assets/leboncoin-logo.svg";
import { ReactComponent as MobsuccessIcon } from "../../../assets/mobsuccess-logo.svg";
import { ReactComponent as WidelyIcon } from "../../../assets/widely-logo.svg";
import type { BusinessUnits } from "../../../types/enums";

const typographyCss = css`
  padding-top: 12px;
`;

const LayoutUI = styled(Container)`
  background: ${({ theme }) => theme.colors.lightGray[600]};
  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    flex-direction: column;
  }
`;

const AuthCardUI = styled(Container)`
  border-radius: 16px;
  box-shadow:
    0px -1px 4px rgba(35, 43, 82, 0.08),
    0px 8px 8px rgba(35, 43, 82, 0.06),
    0px 16px 12px rgba(35, 43, 82, 0.04),
    0px 24px 16px rgba(35, 43, 82, 0.04),
    0px 32px 16px rgba(35, 43, 82, 0.02),
    0px 40px 16px rgba(35, 43, 82, 0.02);
  @media (max-width: ${({ theme }) => theme.breakpoints.phablet}) {
    width: 100%;
    height: 100%;
    padding: 24px;
    box-shadow: none;
  }
`;

const WidelyIconUI = styled(WidelyIcon)`
  height: 48px;
  min-height: 48px;
  max-width: 100%;
`;

const MobsuccessIconUI = styled(MobsuccessIcon)`
  height: 48px;
  min-height: 48px;
  max-width: 100%;
`;

const FarlyIconUI = styled(FarlyIcon)`
  height: 48px;
  min-height: 48px;
`;

const LeboncoinLogoUI = styled(LeboncoinIcon)`
  height: 64px;
  min-height: 48px;
  max-width: 100%;
`;

const icons: Record<BusinessUnits, JSX.Element> = {
  farly: <FarlyIconUI />,
  widely: <WidelyIconUI />,
  mobsuccess: <MobsuccessIconUI />,
  leboncoin: <LeboncoinLogoUI />,
  storysuccess: <MobsuccessIconUI />, // not in figma
};

type AuthCardProps = {
  title: string;
  children: ReactNode;
  businessUnit: BusinessUnits;
};

function AuthCard({
  title,
  children,
  businessUnit,
}: AuthCardProps): JSX.Element {
  const { palette } = useTheme();
  const icon = icons[businessUnit];

  return (
    <LayoutUI width="100vw" height="100vh">
      <Container
        width="100vw"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <AuthCardUI
          width="692px"
          backgroundColor={palette.white}
          alignItems="center"
          justifyContent="center"
          padding="68px 100px"
          flexDirection="column"
          gap="24px"
        >
          <Container
            gap="24px"
            width="100%"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            {icon}
            <Typography
              as="span"
              variant="2xl-bold"
              textAlign="center"
              color={palette.interface.darker[200]}
              styled={typographyCss}
            >
              {title}
            </Typography>
          </Container>
          {children}
        </AuthCardUI>
      </Container>
    </LayoutUI>
  );
}

const MemoizedAuthCard = memo(AuthCard);
export { MemoizedAuthCard as AuthCard };
