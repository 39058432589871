import { sva } from "@mobsuccess-devops/styled-system/css";

export const dropdownStyles = sva({
  slots: [
    "trigger",
    "content",
    "item",
    "itemPrefix",
    "groupLabel",
    "group",
    "checkbox",
  ],
  base: {
    content: {
      backgroundColor: "white",
      borderRadius: "md",
      boxShadow: "md",
      overflow: "auto",
      animation: "appear 0.2s ease-in-out",
      zIndex: 10000,
      width: "fit-content",
      minWidth: "var(--reference-width)",
      maxHeight: "300px",
      outline: "none",
    },
    item: {
      display: "flex",
      alignItems: "center",
      gap: "sm",
      padding: "12px",
      cursor: "pointer",
      border: "0.2px solid transparent",
      transition: "ease-in-out 0.2s",
      color: "interface",
      "&:first-child": {
        borderTopRadius: "md",
      },
      _highlighted: {
        "&:not(:hover)": {
          border: "0.2px solid token(colors.primary.400)",
        },
      },
      "&[data-state='checked']": {
        backgroundColor: "primary.100",
      },
      "&:hover[data-state='checked']": {
        backgroundColor: "primary.200",
        "& > *": {
          color: "primary",
        },
      },
      "&:is(:hover, [data-hover]):not([data-state='checked'])": {
        backgroundColor: "interface.100",
        "& > *": {
          color: "primary",
        },
      },
      "[data-type='group'] + &": {
        borderTop: "1px solid",
        borderColor: "#BCC1DE",
      },
      _disabled: {
        cursor: "not-allowed",
        opacity: 0.5,
        backgroundColor: "white!",
        "& > *": {
          color: "interface!",
        },
      },
    },
    itemPrefix: {
      size: "20px",
    },
    groupLabel: {
      paddingY: "md",
      paddingX: "sm",
      textStyle: "muted",
      color: "interface.400",
      textTransform: "uppercase",
    },
    group: {
      "&:not(:first-child)": {
        borderTop: "1px solid",
        borderColor: "#BCC1DE",
      },
    },
  },
});
