import { memo, useMemo, type JSX } from "react";

import { ReactComponent as ErrorIcon } from "@mobsuccess-devops/streamline/custom/react-ui/danger-indicator.svg";
import { ReactComponent as InfoIcon } from "@mobsuccess-devops/streamline/custom/react-ui/info-indicator.svg";
import { ReactComponent as SuccessIcon } from "@mobsuccess-devops/streamline/custom/react-ui/success-indicator.svg";
import { ReactComponent as WarningIcon } from "@mobsuccess-devops/streamline/custom/react-ui/warning-indicator.svg";

import styled from "styled-components";

type IconUIProps = {
  $variant: "success" | "warning" | "danger" | "info";
};

const IconUI = styled.div<IconUIProps>`
  width: 24px;
  height: 24px;
  color: ${({ theme, $variant }) => theme.palette[$variant].base};
`;

IconUI.displayName = "IconUI";

type IconProps = {
  variant: "success" | "warning" | "danger" | "info";
};

function Icon({ variant }: IconProps): JSX.Element {
  const IconToRender = useMemo(() => {
    switch (variant) {
      case "success":
        return SuccessIcon;
      case "info":
        return InfoIcon;
      case "danger":
        return ErrorIcon;
      case "warning":
        return WarningIcon;
      default:
        return null;
    }
  }, [variant]);

  if (!IconToRender) {
    return <></>;
  }
  return (
    <IconUI $variant={variant}>
      <IconToRender title={`${variant} icone`} />
    </IconUI>
  );
}

const MemoizedComponent = memo(Icon);
export { MemoizedComponent as Icon };
