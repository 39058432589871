import { styled } from "@mobsuccess-devops/styled-system/jsx";
import type {
  HTMLStyledProps,
  RecipeVariantProps,
} from "@mobsuccess-devops/styled-system/types";

import { buttonStyles } from "./styles";

export const WhacamoleButton = styled("button", buttonStyles);
export type WhacamoleButtonProps = HTMLStyledProps<"button"> &
  RecipeVariantProps<typeof buttonStyles>;
