export type ParamTypeMap = {
  string: string;
  number: number;
  boolean: boolean;
  array: string[];
  object: Record<string, string>;
};

const extractors = {
  string: (searchParams: URLSearchParams, name: string) => {
    return searchParams.get(name) ?? null;
  },
  number: (searchParams: URLSearchParams, name: string) => {
    const value = searchParams.get(name);
    if (value !== null && !isNaN(+value)) {
      return +value;
    }
    return null;
  },
  boolean: (searchParams: URLSearchParams, name: string) => {
    if (!searchParams.has(name)) {
      return null;
    }
    const value = searchParams.get(name);
    return !value || !["false", "0", "off"].includes(value);
  },
  array: (searchParams: URLSearchParams, name: string) => {
    const value = searchParams.get(name);
    if (value !== null) {
      if (value === "") {
        return [];
      }
      return value.split(",");
    }
    return null;
  },
  object: (searchParams: URLSearchParams, name: string) => {
    const array = Array.from(searchParams.entries()).filter(([key]) =>
      key.startsWith(name),
    );

    const object = array.reduce(
      (acc: Record<string, string> | undefined, [key, value]) => {
        const keyName = key.replace(name, "");
        return {
          ...(acc ?? {}),
          [keyName]: value,
        };
      },
      undefined,
    );
    return object ?? null;
  },
};

const assigners = {
  string: (searchParams: URLSearchParams, name: string, value: string) => {
    searchParams.set(name, value);
  },
  number: (searchParams: URLSearchParams, name: string, value: number) => {
    searchParams.set(name, value.toString());
  },
  boolean: (searchParams: URLSearchParams, name: string, value: boolean) => {
    searchParams.set(name, value.toString());
  },
  array: (searchParams: URLSearchParams, name: string, value: string[]) => {
    searchParams.set(name, value.join(","));
  },
  object: (
    searchParams: URLSearchParams,
    name: string,
    value: Record<string, string>,
  ) => {
    const presents = Array.from(searchParams.keys()).filter((key) =>
      key.startsWith(name),
    );
    presents.forEach((key) => {
      searchParams.delete(key);
    });
    Object.entries(value).forEach(([key, value]) => {
      searchParams.set(`${name}${key}`, value);
    });
  },
};

const deletors = {
  string: (searchParams: URLSearchParams, name: string) => {
    searchParams.delete(name);
  },
  number: (searchParams: URLSearchParams, name: string) => {
    searchParams.delete(name);
  },
  boolean: (searchParams: URLSearchParams, name: string) => {
    searchParams.delete(name);
  },
  array: (searchParams: URLSearchParams, name: string) => {
    searchParams.delete(name);
  },
  object: (searchParams: URLSearchParams, name: string) => {
    const presents = Array.from(searchParams.keys()).filter((key) =>
      key.startsWith(name),
    );
    presents.forEach((key) => {
      searchParams.delete(key);
    });
  },
};

const comparators = {
  string: (value: string, defaultValue: string) => {
    return value === defaultValue;
  },
  number: (value: number, defaultValue: number) => {
    return value === defaultValue;
  },
  boolean: (value: boolean, defaultValue: boolean) => {
    return value === defaultValue;
  },
  array: (value: string[], defaultValue: string[]) => {
    return (
      (value ?? []).length === defaultValue.length &&
      (value ?? []).every((v, i) => v === defaultValue[i])
    );
  },
  object: (
    value: Record<string, string>,
    defaultValue: Record<string, string>,
  ) => {
    return (
      Object.keys(value).length === Object.keys(defaultValue).length &&
      Object.entries(value).every(([key, value]) => {
        return value === defaultValue[key];
      })
    );
  },
};

export class SearchParams extends URLSearchParams {
  getTyped(name: string, options: { type: "string" }): string | null;
  getTyped(
    name: string,
    options: { type: "string"; defaultValue: string },
  ): string;
  getTyped(name: string, options: { type: "number" }): number | null;
  getTyped(
    name: string,
    options: { type: "number"; defaultValue: number },
  ): number;
  getTyped(name: string, options: { type: "boolean" }): boolean | null;
  getTyped(
    name: string,
    options: { type: "boolean"; defaultValue: boolean },
  ): boolean;
  getTyped(name: string, options: { type: "array" }): string[] | null;
  getTyped(
    name: string,
    options: { type: "array"; defaultValue: string[] },
  ): string[];
  getTyped(
    name: string,
    options: { type: "object" },
  ): Record<string, string> | null;
  getTyped(
    name: string,
    options: { type: "object"; defaultValue: Record<string, string> },
  ): Record<string, string>;
  getTyped(
    name: string,
    options: { type: keyof ParamTypeMap },
  ): ParamTypeMap[keyof ParamTypeMap] | null;
  getTyped(
    name: string,
    options: {
      type: keyof ParamTypeMap;
      defaultValue?: ParamTypeMap[keyof ParamTypeMap];
    },
  ): ParamTypeMap[keyof ParamTypeMap] | null {
    const value = extractors[options.type](this, name);
    if (value === null) {
      return options.defaultValue ?? null;
    }
    return value;
  }

  setTyped(
    name: string,
    value: string,
    options: { type: "string"; defaultValue?: string },
  ): void;
  setTyped(
    name: string,
    value: number,
    options: { type: "number"; defaultValue?: number },
  ): void;
  setTyped(
    name: string,
    value: boolean,
    options: { type: "boolean"; defaultValue?: boolean },
  ): void;
  setTyped(
    name: string,
    value: string[],
    options: { type: "array"; defaultValue?: string[] },
  ): void;
  setTyped(
    name: string,
    value: Record<string, string>,
    options: { type: "object"; defaultValue?: Record<string, string> },
  ): void;
  setTyped<T extends keyof ParamTypeMap>(
    name: string,
    value: ParamTypeMap[T],
    options: { type: T; defaultValue?: ParamTypeMap[T] },
  ): void {
    const comparator = comparators[options.type];
    if (
      options.defaultValue !== undefined &&
      // @ts-expect-error -- TS doesn't understand the type narrowing
      comparator(value, options.defaultValue)
    ) {
      return deletors[options.type](this, name);
    }
    // @ts-expect-error -- TS doesn't understand the type narrowing
    assigners[options.type](this, name, value);
  }

  private explicitRemovals: Set<string> = new Set();

  getExplicitRemovals(): string[] {
    const removals = Array.from(this.explicitRemovals);
    this.explicitRemovals.clear();
    return removals;
  }

  delete(name: string, value?: string): void {
    if (this.get(name) === value || value === undefined) {
      this.explicitRemovals.add(name);
    }
    super.delete(name, value);
  }

  deleteTyped(name: string, options: { type: keyof ParamTypeMap }): void {
    deletors[options.type](this, name);
  }

  toString(): string {
    const str = super.toString();
    return str ? `?${str}` : "";
  }
}
