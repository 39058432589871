import {
  useCallback,
  useState,
  useMemo,
  type FormEventHandler,
  type FocusEventHandler,
  type JSX,
} from "react";

import { t, Trans } from "@lingui/macro";
import { DarkButton } from "@mobsuccess-devops/react-ui/Button";
import { Container } from "@mobsuccess-devops/react-ui/Container";
import { FieldLayout } from "@mobsuccess-devops/react-ui/Form";
import { FieldDecorators } from "@mobsuccess-devops/react-ui/Form/Field/decorators";
import { Typography } from "@mobsuccess-devops/react-ui/Typography";
import { Input } from "@mobsuccess-devops/react-ui/_PandaArk";
import { css } from "@mobsuccess-devops/styled-system/css";

import styled, { css as styledCss, useTheme } from "styled-components";

import { usePasswordValidation } from "../../../features/password";
import { AuthActionEnum, useAuth } from "../../../public/auth/auth";

import PasswordTooltip from "./PasswordTooltip";

const FormUI = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 24px;
`;

const paswordLostCss = styledCss`
  text-decoration: underline;
  cursor: pointer;
`;

type ResetPasswordProps = {
  onChangeAuthAction: (action: AuthActionEnum, state?: object) => void;
};

function ResetPassword({
  onChangeAuthAction,
}: ResetPasswordProps): JSX.Element {
  const { palette } = useTheme();
  const { resetPassword } = useAuth();

  const { code, email } = useMemo(() => {
    const search = new URLSearchParams(window.location.search);
    return {
      code: search.get("code"),
      email: search.get("email"),
    };
  }, []);

  const [hasSubmit, setHasSubmit] = useState(false);
  const [errors, onChangeForm] = usePasswordValidation();

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      // get the values from the form event
      e.preventDefault();
      setHasSubmit(true);

      if (
        errors.confirm ||
        errors.password.mandatory.some((rule) => !rule.isValid)
      ) {
        return;
      }

      const password = e.currentTarget.password.value;

      await resetPassword({
        code: String(code),
        email: String(email),
        password,
      });
      onChangeAuthAction(AuthActionEnum.SignIn);
    },
    [
      code,
      email,
      errors.confirm,
      resetPassword,
      onChangeAuthAction,
      errors.password.mandatory,
    ],
  );

  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const handlePasswordFocus = useCallback<FocusEventHandler<HTMLInputElement>>(
    (e) => {
      setIsPasswordFocused(e.type === "focus");
    },
    [],
  );

  const handleBackToLogin = useCallback(() => {
    onChangeAuthAction(AuthActionEnum.SignIn);
  }, [onChangeAuthAction]);

  const { decorators, state } = useMemo(() => {
    const decorators: Record<string, JSX.Element[]> = {
      confirm: [],
      password: [],
    };
    const state: Record<string, "error" | undefined> = {
      confirm: undefined,
      password: undefined,
    };
    if (!hasSubmit) {
      return {
        decorators,
        state,
      };
    }
    if (errors.confirm) {
      state.confirm = "error";
      decorators.confirm.push(
        <FieldDecorators.Feedback state="error" position="end">
          <Trans>
            #Auth.ResetPassword.password-validation-error.miss-match
          </Trans>
        </FieldDecorators.Feedback>,
      );
    }
    if (errors.password.mandatory.some((rule) => !rule.isValid)) {
      state.password = "error";
      decorators.password.push(
        <FieldDecorators.Feedback state="error" position="end">
          <Trans>#Auth.ResetPassword.password-validation-error.weak</Trans>
        </FieldDecorators.Feedback>,
      );
    }
    return {
      decorators,
      state,
    };
  }, [errors.confirm, errors.password.mandatory, hasSubmit]);

  const [passwordInputElement, setPasswordInputElement] =
    useState<HTMLDivElement | null>(null);

  return (
    <FormUI id="auth-form" onSubmit={handleSubmit} onChange={onChangeForm}>
      <PasswordTooltip
        errors={errors.password}
        show={isPasswordFocused}
        anchorElement={passwordInputElement}
      >
        <FieldLayout
          required
          name="password"
          label={t`#Auth.ResetPassword.password.label`}
          decorators={decorators.password}
          className={css({
            width: "100%",
          })}
        >
          <Input.Root
            required
            size="md"
            name="password"
            type="password"
            state={state.password}
            autoComplete="new-password"
            onBlur={handlePasswordFocus}
            onFocus={handlePasswordFocus}
            containerRef={setPasswordInputElement}
            placeholder={t`#Auth.ResetPassword.password.placeholder`}
          />
        </FieldLayout>
      </PasswordTooltip>
      <FieldLayout
        required
        name="confirm"
        label={t`#Auth.ResetPassword.confirm-password.label`}
        decorators={decorators.confirm}
        className={css({
          width: "100%",
        })}
      >
        <Input.Root
          required
          size="md"
          name="confirm-password"
          type="password"
          autoComplete="new-password"
          state={state.confirm}
          placeholder={t`#Auth.ResetPassword.confirm-password.placeholder`}
        />
      </FieldLayout>
      <Container
        flexDirection="column"
        gap="12px"
        width="100%"
        alignItems="center"
      >
        <DarkButton
          fill
          size="lg"
          type="submit"
          formNoValidate
          form="auth-form"
        >
          <Trans>#Auth.ResetPassword.submit</Trans>
        </DarkButton>
        <Typography
          as="span"
          styled={paswordLostCss}
          color={palette.primary.base}
          onClick={handleBackToLogin}
        >
          <Trans>#Auth.ResetPassword.back</Trans>
        </Typography>
      </Container>
    </FormUI>
  );
}

export default ResetPassword;
