import { useMemo, type ComponentType } from "react";

import { useAuth } from "@mobsuccess-devops/react-shared/auth";
import { axiosGet } from "@mobsuccess-devops/react-shared/fetch";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";

type NavbarUser = {
  admin: boolean;
  rights?: object;
  user_firstname: string;
  user_id: string;
};

type Navbar = {
  account: {
    account_id: number;
    account_name: string;
    publisher_currency: "USD" | "EUR";
  };
  client: {
    client_id: string;
    client_label: string;
  };
  user: NavbarUser;
  footerMenu: {
    logoutUrl: string;
    adminUrl: string;
    pages: Array<{
      url: string;
      label: string;
      Icon?: ComponentType;
      iconSrc?: string;
    }>;
  };
  sections: Array<{
    current: boolean;
    iconSrc?: string;
    Icon?: ComponentType;
    label: string;
    pageUrl: string;
    entrypoint: string;
  }>;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions -- this is an interface augmentation
  interface Window {
    _msNavbarData?: Navbar;
  }
}

export function useUnsafeQueryNavbar(): UseQueryResult<Navbar> {
  const { bearer } = useAuth();
  return useQuery({
    queryKey: ["platform/sidebar-config"],
    queryFn: async () => {
      const { data } = await axiosGet<Navbar>({
        entity: "dashboardNavbar",
        verb: "get",
        token: bearer,
        params: {
          version: 2,
        },
      });
      window._msNavbarData = data;
      return {
        ...data,
        account: {
          ...data.account,
          account_id: +data.account.account_id,
        },
      };
    },
  });
}

export function useQueryNavbar(): Extract<
  UseQueryResult<Navbar>,
  { isSuccess: true }
> {
  const query = useUnsafeQueryNavbar();
  if (!query.isSuccess) {
    throw new Error(
      "You should not call this hook outside the proper wrappers (see react-shared EnsureUser)",
    );
  }
  return query;
}

function getRights(obj: object, prefix: string[] = []): Set<string> {
  const keys = new Set<string>();
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === "object") {
      keys.add([...prefix, key].join("."));
      const subright = getRights(value, [...prefix, key]);
      for (const right of subright) {
        keys.add(right);
      }
    } else {
      if (value) {
        keys.add([...prefix, key].join("."));
      }
    }
  }
  return keys;
}

export function useLoggedInUser(): Omit<NavbarUser, "rights"> & {
  rights: Set<string>;
} {
  const { data: navbar } = useQueryNavbar();
  return useMemo(() => {
    const rights = getRights(navbar.user.rights ?? {});
    return {
      ...navbar.user,
      rights,
    };
  }, [navbar]);
}

export function useIsAdmin(): boolean {
  const user = useLoggedInUser();
  return !!user.admin;
}
