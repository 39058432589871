import { cva } from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";

const subTitleStyle = cva({
  base: {
    textStyle: "subTitle",
    color: "interface",
  },
});

export const SubTitle = styled("h2", subTitleStyle);
