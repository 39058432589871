import { captureException } from "@sentry/react";

class SentryFetchError extends Error {
  constructor({ response }: { response: Response }) {
    const { status, url } = response;
    super(
      `Received HTTP code ${status} when fetching from ${new URL(url).hostname}`,
    );
    this.name = "SentryFetchError";
  }
}

export const nativeFetch = window.fetch;

async function fetchInterceptErrors(
  this: Window,
  ...args: Parameters<Window["fetch"]>
) {
  const response = await nativeFetch.apply(this, args);
  const { status } = response;
  // we purposely ignore 400 errors emitted by Cognito when the user has provided a bogus username/password combination
  if (
    status >= 400 &&
    !(
      response.status === 400 &&
      response.url.match(
        /^https:\/\/cognito-idp.[a-z0-9-]+.amazonaws.com\/$/,
      ) &&
      response.headers.get("x-amzn-errortype") == "NotAuthorizedException:"
    )
  ) {
    // log error to Sentry
    captureException(new SentryFetchError({ response }), {
      extra: { ...response },
      tags: {
        interceptor: "FetchInterceptor",
        httpStatusCode: status,
      },
    });
  }
  return response;
}

export function initFetchInterceptor(): void {
  Object.assign(window, { fetch: fetchInterceptErrors });
}
